.main {
    display: grid;
    grid-template-columns: 20% 80%;
    flex-direction: row;
    width: 100%;
    flex-shrink: 1;
    flex-grow: 1 10 1;
    height: calc(100vh - 123px);
    /* background-color: blue; */
    margin: 0 !important;
    padding: 0 !important;
}

.leftPanel {
    /* background-color: #a0c5b4; */
    display: grid;
    grid-template-rows: 100%;
}
.centerPanel {
    display: flex;
    flex-direction: column;
}

.final {
    overflow: scroll !important;
}